import React from "react";
import styles from "./reviewcomponent.module.css";
import star from '../../assets/5starPNG.png'

const Reviewcomponent = () => {
  return (
    <div className={styles.bdacontainer}>
      <h1>Our Reviews</h1>
    <div className={styles.container}>

      
      <div className={styles.first}>

           <div className={styles.image}>
            <p>
            Overall rating of <br></br>5 out of 5 Stars
            </p>
        <img src={star} alt='/'/>
        </div>
        <div className={styles.one}> <div className={styles.o}></div></div>
        <div className={styles.two}> <div className={styles.t}></div></div>
        <div className={styles.three}><div className={styles.th}></div></div>
        <div className={styles.four}> <div className={styles.f}></div></div>
        <div className={styles.five}> <div className={styles.fi}></div></div>
      </div>
      <div className={styles.second}>
        <h1> Leave a Review</h1>

        <p>
          At our company, we value the feedback and experiences of our clients.
          Your reviews help us improve our services and provide the best
          possible experience. Click the button below to leave your review on
          our Google review page. Thank you for your support!
        </p>

        <a href="https://g.co/kgs/1Zp17o">
          <button>Write a Review</button>
        </a>
      </div>
    </div>
    </div>
  );
};

export default Reviewcomponent;
