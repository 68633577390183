import React from 'react'
import styles from './disclaimer.module.css'

const Disclaimer = () => {
  return (
    <div className={styles.container}>
        <h1>Disclaimer</h1>
        <p>
"APSM Financial Services Ltd is an Appointed Representative of Stonebridge Mortgage Solutions Ltd, which is authorised and regulated by the Financial Conduct Authority. We are entered on the Financial Services Register under firm reference number 990406.

<br></br>Your home is at risk if you do not keep up repayments on a mortgage or other loan secured against it."</p>

    </div>
  )
}

export default Disclaimer