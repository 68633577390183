import React from "react";
import styles from "./hero.module.css";
import parul from "../../assets/parul.jpeg";
import { Link } from 'react-router-dom';



const Hero = () => {

  


  return (
    <div className={styles.container}>
      <img src={parul} alt="/"></img>

      <div className={styles.smallcontainer}>
        <div className={styles.first}>
          {" "}
          <h1>Hello</h1>
        </div>
        <div className={styles.second}>
          <h2>A Bit About Me</h2>
          <p>
            My name is Parul Chhabra, a friendly and professional Mortgage and
            Protection Adviser based in Staines, serving Middlesex and the
            broader South East region.
          </p>
        </div>
        <div className={styles.third}>
          <p>
            As the founder of APSM Financial Services, I bring 13 years of
            industry experience. I take great pride in the quality of advice and
            the high level of service I provide.
          </p>
        </div>
        <div className={styles.fourth}>
          <p>
            Please do no hesitate to get in touch if you would like a
            complimentary review of your mortgage or protection needs. I am
            available for appointments at your convenience, whether at your home
            or office, including evenings.
          </p>
        </div>
        <div className={styles.fifth}>
          <div className={styles.one}> <Link to ="/Mortgages">Mortgages</Link></div>
          <div className={styles.two}><Link to="/Protection">Protection</Link></div>
          <div className={styles.three}> <Link to="/homeinsurance">Home Insurance</Link></div>
          <div className={styles.four} ><Link to="/contact">Contact</Link></div>
          <div className={styles.five}><Link to="/MoreInfo"> More Info</Link></div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
