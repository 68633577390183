import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
const Contact = () => {
  const form = useRef();

  const [isPopupOpen, setPopupOpen] = useState(true);
  const sendEmail = (e) => {
    e.preventDefault();
    console.log("in email");

    emailjs
      .sendForm(
        "service_ucshh1l",
        "template_7l4gvwd",
        form.current,
        "PdypaaFUwxF5UcG2M"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <>
      <div className="w-full h-screen   grid justify-items-center items-center">
        <div className="   md:grid grid-cols-2 gap-8 md:w-11/12">
          <div>
            <div className=" md:text-5xl text-2xl w-full md:font-abc text-center md:text-start text-orange-400 m-4">
              GET IN TOUCH
            </div>
            <marquee direction="left" width="100%" height="30">
              "Not all buy to let mortgages are regulated by the financial
              conduct authority." "As with all insurance policies, conditions
              and exclusions will apply."{" "}
            </marquee>
            <div className=" text-sm md:text-lg md:font-normal m-6 font-letter">
              For dependable advice and a fee-free review of your current
              situation, please leave your details below and I will call you
              back with more information.
            </div>
            <div className=" text-sm md:text-lg md:font-normal m-6 font-letter">
              41 Wapshott Road Staines Upon Thames TW18 3HB
            </div>
            <div className=" text-sm md:text-lg md:font-normal m-6 font-letter">
              parul.chhabra@apsmfs.co.uk
            </div>
            <div className=" text-sm md:text-lg md:font-normal m-6 font-letter">
              +44 7951 054958
            </div>
          </div>
          <div>
            <form ref={form} className="m-4 grid gap-2">
              <div>
                <label className="block mb-2 text-sm font-letter text-gray-900 ">
                  Name{" "}
                </label>
                <input
                  type="name"
                  name="user_name"
                  id="Name"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                  placeholder="Name"
                  required
                />
              </div>{" "}
              <div>
                <label className="block mb-2 text-sm font-letter text-gray-900 ">
                  Email{" "}
                </label>
                <input
                  type="email"
                  name="user_email"
                  id="Email"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                  placeholder="Email"
                  required
                />
              </div>{" "}
              <div>
                <label className="block mb-2 text-sm font-letter text-gray-900 ">
                  Phone{" "}
                </label>
                <input
                  name="phone"
                  type="text"
                  id="Phone"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                  placeholder="Phone"
                  required
                />
              </div>
              <div className="text-sm font-letter">
                How would you like to be contacted ?
              </div>
              <div>
                <input
                  name="user_email"
                  type="checkbox"
                  id="Email"
                  className=" checked:bg-blue-500 ..."
                />
                <span className="ml-2 text-sm font-letter">Email</span>
              </div>
              <div>
                <input
                  type="checkbox"
                  id="Phone"
                  className=" checked:bg-blue-500 ..."
                />
                <span className="ml-2 text-sm font-letter">Phone</span>
              </div>
              <div className="text-lg ">Leave us a message</div>
              <textarea
                className="border h-44"
                name="message"
                placeholder="Leave us a message"
              ></textarea>
              <div>
                <input
                  type="checkbox"
                  id="Phone"
                  className=" checked:bg-blue-500 ..."
                />
                <span className="ml-2 text-sm font-letter">
                  Tick the box if you want your details to be stored in our
                  database,which may be used for marketing purposes
                </span>
              </div>
              <button
                type="submit"
                className=" bg-transparent hover:bg-orange-300 text-orange-300 font-semibold hover:text-white py-2 px-4 border border-orange-300 hover:border-transparent rounded mb-4 w-1/3"
                onClick={(e) => {
                  sendEmail(e);
                }}
              >
                {" "}
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>

      {isPopupOpen && (
        <div className="w-[80vw]  absolute  inset-0 m-auto top-10  xs:h-[60vh] sm:h-[100vh] sm:w-[70vw]">
          <iframe
            src="https://drive.google.com/file/d/1fJfpXxDqVrI4v-v6lWciM3tdAX2EW8we/preview"
            width="100%"
            height="90%"
            title="pdf viewer"
          />

          <div className="bg-black opacity-80  xs:w-[80vw] sm:w-[70vw]">
          <button
            onClick={() => {
              setPopupOpen(false);
            }}
            className="bg-lime-500 w-36 text-white font-bold  mt-2 mb-2 ml-2  rounded-md hover:bg-pink-700"
          >
            I Accept{" "}
          </button>  </div>
          </div>
        
      )}
    </>
  );
};

export default Contact;
