import React from 'react'
import {BsArrowDown} from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'

const HomeInsurance = () => {
   const Navigate = useNavigate()
  return (
    <>
    <>
    <div className='h-full w-full grid     justify-items-center p-4'>
      <div className=''>
       <div className='text-3xl md:text-7xl font-head2 text-center'>Why Home Insurance is Important</div>
       <marquee direction="left" width="100%" height="30">"Not all buy to let mortgages are regulated by the financial conduct authority."  "As with all insurance policies, conditions and exclusions will apply." </marquee>
       <div className='grid justify-items-center '>

       <div className='text-lg font-letter text-center md:w-11/12 '>Home insurance is an investment that you take on to protect your home and family comforts. It is available in two different forms: buildings insurance and contents insurance, or you can opt for a comprehensive plan that will include both. Home insurance offers you a certain amount of cover, which you can use to rebuild your home should disaster strike.

Your home is by far the most valuable thing that you own and many of you will have spent years saving for it. Without insurance, it's unlikely that you'd be able to afford to repair any damage that should occur if a fire or flood should take place, which is why it's so important.</div>
       </div>
         
      </div>
   
        
         
          
          
           
    </div>
     <div className='h-fit w-full grid gap-5 justify-items-center'>
      <div className=' md:text-5xl text-xl  text-center '>WHAT ELSE DO YOU NEED TO KNOW?</div>
       <div className='grid gap-5 grid-rows-2 md:w-11/12 md:grid-rows-none md:grid-cols-2'>
       <div className='grid gap-5 text-center md:text-start'>
          <div className='font-head2 text-5xl text-orange-500'>Buildings and Contents Cover</div>
          <div className='font-letter text-lg '>* A standard policy should cover you for fire, wind, hail, explosion, smoke damage, and damage that has been caused by criminal activity such as vandalism.<br/>

* Buildings insurance will cover the structure of a property including floors, painted walls, fixtures, and fittings, which are things such as radiators, baths and showerheads.<br/>

* Contents insurance will cover all the items within your home: laptops, electrical items, furniture, jewellery, pictures and so on. Some policies will also cover bicycles and mobile phones.<br/>

Contents insurance will also cover fire and burglary. It can also cover accidental damage and theft of itens when you are away from your home. It will either pay for a replacement of your items or the market value of your item.</div>
          
           
           
            
        </div>
        <div>
          <div className='font-head2 text-5xl text-orange-500 text-center md:text-start'>LANDLORD INSURANCE</div>
          <div className='font-letter text-lg '>Landlord insurance covers the risks you face when letting or leasing a property that standard building and contents insurance may not cover. Also known as buy-to-let insurance, it covers you for damage to the buildings or to your contents against a range of perils such as flood, fire, burst pipes or storm. You can also choose from several optional covers like accidental damage, loss of rent, landlord emergency and legal expenses cover which covers the costs and expenses in dealing with a range of legal matters such as contract disputes, tax and VAT investigations, repossession and eviction of squatters.</div>
        </div>

       </div>
       <div className='text-xl font-gead2'>Solicitors, valuers and surveyors are not regulated by the Financial Conduct Authority.</div>
       <div className='text-white bg-black w-full text-center p-8'>The information on this website if for the use of residents of the United Kingdom only. No representations are made as to whether the information is available or applicable in any other country which may have access to it.</div>
     </div>

    
    </>
    </>
  )
}

export default HomeInsurance