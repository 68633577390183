import React from 'react'
import styles from './footer.module.css'

const Footer = () => {
  return (
    <div className={styles.container} >
        <div>
            <h3>Phone</h3>
            <p>++44 7951054958</p>
        </div>
        <div>
            <h3>Email</h3>
            <p>parul.chhabra@apsmfs.co.uk</p>
        </div>
        <div className={styles.third}>
            <h3>Address</h3>
            <p>41 Wapshott Road Staines Upon Thames TW18 3HB</p>
        </div>

    </div>
  )
}

export default Footer