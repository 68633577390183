import React from 'react'

const Protection = () => {

  return (
    <>
      <div className='w-screen  h-fit  grid justify-items-center gap-5 lg:gap-0 p-4'>
        <div className='grid grid-rows-2 h-fit pb-2 '>
          <div className='font-head2 text-5xl lg:text-6xl text-center '>PROTECTION</div>
          <marquee direction="left" width="100%" height="30">"Not all buy to let mortgages are regulated by the financial conduct authority."  "As with all insurance policies, conditions and exclusions will apply." </marquee>
          <div className='font-letter lg:text-xl  text-center '>Life and Protection Insurance policies can protect you and your family from the financial consequences of death, a serious accident or illness, or unemployment.</div>
        </div>
        <div className='w-full grid  gap-5 justify-items-center items-center lg:w-11/12 lg:grid-cols-3 '>
          <div className='border shadow-lg cursor-pointer h-full hover:shadow-2xl  rounded-xl overflow-hidden ' >
            <img src='../protection1.webp' className='w-full' alt=''></img>
            <div className='text-center text-xl font-letter p-5 text-orange-500'>CRITICAL ILLNESS INSURANCE</div>
            <div className='font-letter p-5 text-center '> Critical Illness Insurance provides a tax-free lump sum payment upon the diagnosis of certain life-threatening or severely debilitating conditions, such as heart attack, stroke, cancer, and major organ transplants.</div>

          </div>
          <div className='border shadow-lg cursor-pointer h-full hover:shadow-2xl rounded-xl overflow-hidden  ' >
            <img src='../protection2.jpg' className='w-full' alt=''></img>
            <div className='text-center text-xl text-orange-500 font-letter p-5'>LIFE INSURANCE</div>
            <div className='p-3 font-letter text-center'> Life Insurance (also known as Life Assurance) offers financial security for those who depend on you in the event of your death. While money cannot replace a loved one, it can help those left behind manage financially. For instance, it could be used to pay off the mortgage or provide an income to cover regular household expenses.</div>
            {/* <div className='p-1 font-letter pb-2 text-center'>Although money can’t replace a loved one, it can help those left behind to weather the financial storm. For example, it could pay off the mortgage or provide an income to help cover regular household expenditure.</div> */}

          </div>
            <div className='border shadow-lg cursor-pointer h-full  hover:shadow-2xl rounded-xl overflow-hidden  ' >
            <img src='../protection3.jpg' className='w-full' alt=''></img>
            <div className='text-center font-letter text-xl text-orange-500 p-5'>INCOME PROTECTION</div>
            <div className='p-3 font-letter text-center'>Income Protection Insurance provides a regular, tax-free replacement income if you are unable to work due to illness, injury, or, in some cases, unemployment. It can help you maintain mortgage repayments and cover other living expenses until you are able to return to work.

</div>
            {/* <div className='p-5 font-letter text-center'>It could help you keep up with your mortgage repayments and other living costs until you’re able to return to work.</div> */}

          </div>
          <div>

          </div>
        </div>
      </div>
    </>
  )
}

export default Protection