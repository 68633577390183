import React from 'react'



const Privacypolicy = () => {
  return (
    <div>

        
        <iframe 
        src="https://drive.google.com/file/d/1PavMkgAG9pudmpPCfFzDr-v18-4tqdkU/preview"
        width="100%"
        height="800px"
        title="pdf viewer" />

    </div>
  )
}

export default Privacypolicy