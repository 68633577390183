import React from 'react'
import Header
 from '../Components/home/header'
 import styles from './home.module.css'
import Hero from '../Components/home/hero'

import Disclaimer from '../Components/home/disclaimer'
import Footer from '../Components/footer'
import Reviewcomponent from '../Components/home/reviewcomponent'

const Home = () => {
  return (
    <div className={styles.container}>
        <Header/>

        <marquee direction="left" width="100%" height="20">"Not all buy to let mortgages are regulated by the financial conduct authority."  "As with all insurance policies, conditions and exclusions will apply." </marquee>
     
        <Hero/>
     
       
       <Reviewcomponent/>
       <Disclaimer/>

       <div className={styles.policy}>
        <a href='./privacypolicy'>Privacy Policy</a>
        
        </div>

        <Footer></Footer>
        
        {/* <Review/> */}
    </div>
  )
}

export default Home