import React from 'react'
import { useNavigate } from 'react-router-dom'

const MortgagesInfo = () => {

  const data1=[
    {
      title:'FIXED RATE',
      desc:'With a fixed-rate mortgage, the interest rate remains constant, ensuring that your payments remain consistent for an agreed-upon duration. Upon expiration of this period, the lender typically transitions you to its SVR (Standard Variable Rate), as outlined in the "Variable rate" section. Exiting your lender during the fixed-rate period might incur penalties, and paying additional amounts before the terms end may also trigger an early repayment charge. While a fixed-rate mortgage simplifies budgeting by keeping payments steady, regardless of interest rate fluctuations, it also means you will not benefit from rate decreases.'
    },
    {
      title:'TRACKER RATE',
      desc:'With a tracker rate mortgage, your monthly payment adjusts in accordance with a rate that is typically either lower or, more commonly, higher than a selected Base Rate (usually the Bank of England Base Rate). The mortgage rate "tracks" this chosen rate, usually for a specified period, often two to three years. Exiting the lender during the tracker period might entail a penalty, and overpaying during this time may trigger an early repayment charge. Opting for a tracker mortgage may be suitable if you can afford higher payments when interest rates rise, as you will benefit when rates decrease. However, it is not advisable if your budget cannot accommodate increased monthly payments.',
    },
    {
      title:'FLEXIBLE MORTGAGES',
      desc:'Flexible mortgages offer the flexibility to overpay, underpay, or even take a payment holiday. Any unpaid interest accrues and is added to the outstanding mortgage, while overpayments reduce it. Some schemes also allow drawing down additional funds up to a pre-agreed limit.',
    },
    // {
    //   title:'GOVERNMENT-BACKED SCHEMES',
    //   desc:'In recent years, the government has supported various schemes, such as "Help to Buy," aimed at assisting homebuyers. We can provide comprehensive information about these schemes and determine if you are eligible to benefit from them. For further details, please refer to our Help to Buy guide.',
    // },
    {
      title:'DISCOUNTED RATE',
      desc:'With a discounted rate mortgage, similar to a variable rate mortgage, your monthly payments can fluctuate. However, you receive a discount on the lender’s SVR for a predetermined period, after which you typically transition to the full SVR. Penalties may apply for overpayments and early repayment, and the lender may opt not to reduce its variable rate, even if the Base Rate decreases or delays doing so. Discounted rate mortgages can provide a softer introduction to your mortgage obligations, especially during financially challenging times. However, it is crucial to ensure you can manage the payments when the discount period ends and the rate rises.',
    },
    {
      title:'OFFSET MORTGAGES',
      desc:'Opting for an offset mortgage allows you to utilize your savings to decrease both your mortgage balance and the associated interest payments. For instance, if you borrowed £200,000 but had £50,000 in savings, you would only accrue interest on £150,000. While offset mortgages typically entail higher costs compared to standard deals, they can lower your monthly payments while still granting access to your savings',
    },
  ]

  const Card=({title,desc})=>{
    return(
    <div>
      <h4 className='col-span-1 text-xl font-bold text-orange-400'>{title}</h4>
      <p className='text-lg'>{desc}</p>
    </div>
    )
  }
  const Navigate = useNavigate()
  return (
    <>
      <div className='w-full h-fit grid justify-items-center items-center gap-5 '>
        <div className='w-full grid justify-items-center'>
          <div className=' text-3xl font-letter text-center md:text-6xl'>MORTGAGE ADVICE YOU CAN DEPEND ON</div>
          <div className='font-letter text-lg text-center md:px-40'>Whether buying your first home, buying to let, or remortgaging it’s a big commitment. I aim to help you understand what you need to think about making you feel more confident about your financial decisions.</div>
        </div>
        <div className='grid justify-items-center'>
          <div className=' text-3xl md:text-5xl text-orange-400 font-abc'>TYPES OF MORTGAGES</div>
          <div className='md:text-xl text-lg font-letter md:w-11/12 text-center'>Before settling on a particular deal, you should determine which type of mortgage best suits your requirements. A variable rate mortgage means that your monthly payment varies according to a Standard Variable Rate (SVR) of interest, which is determined by the lender. If you opt to switch lenders, you likely won't face penalties, and you may also be able to repay extra amounts without incurring penalties. However, 
          many lenders do not offer their standard variable rate to new borrowers.</div>
        </div>

        <div className='  p-4 grid grid-cols-1 md:grid-cols-2 gap-4'>
          {data1.map((item,i)=><Card key={i} title={item.title} desc={item.desc} />)}


        </div>

        <div className='grid justify-items-center gap-5 md:w-11/12'>
          <div className='font-abc text-orange-400 text-3xl md:text-5xl'>REPAYMENT METHODS</div>
          <div className='font-letter text-lg text-center'>The two most common ways of repaying your mortgage are capital repayment and interest only.</div>
          <div className='grid grid-rows-2 md:grid-rows-none gap-5 md:grid-cols-2'>
            <div className='grid gap-5'>
              <div className='font-letter text-orange-400'>CAPITAL REPAYMENT</div>
              <div className='font-letter'>With a capital repayment mortgage, your monthly payments consist of both interest on the borrowed amount and a portion to repay the principal sum (the amount borrowed). The advantage of capital repayment is the visible reduction of the mortgage balance each year, albeit gradual in the initial years, and the assurance of debt repayment by the end of the mortgage term, provided payments are sustained. Opting for a shorter mortgage term leads to larger monthly payments, while a longer term may yield lower monthly payments but entails paying more interest over the term. It's essential to consider how soon you aim to be mortgage-free and balance this with a mortgage term that ensures affordability of monthly payments.</div>
            </div>


            <div className='grid    gap-5 '>
              <div className='font-letter text-orange-400'>INTEREST ONLY</div>
              <div className='font-letter'>Choosing an interest-only loan means your monthly payments solely cover the interest on the mortgage balance. The principal amount (the borrowed sum) remains unchanged and must be repaid at the mortgage term's conclusion. Thus, you'll require a separate investment or a combination of investments to accumulate the necessary capital, and you must demonstrate affordability for this. It's important to note that the value of investments can fluctuate, and there's no guarantee of recouping the original investment amount. With an interest-only mortgage, you must present a repayment plan to the lender for the end of the interest-only period. Failure to generate sufficient funds to repay the mortgage by the term's end might necessitate selling your property.</div>
            </div>

          </div>
        </div>

        <div className='w-full grid justify-items-center'> <button className=" bg-transparent hover:bg-orange-300 text-orange-300 font-semibold hover:text-white py-2 px-4 border border-orange-300 hover:border-transparent rounded mb-4  w-11/12 md:w-1/3" onClick={() => { Navigate("/Contact") }}

        > Get In Touch</button> </div>
        <div className='bg-black p-8 text-white w-full text-center  font-abc'>Your home may be repossessed if you do not keep up repayments on your mortgage </div>
      </div>

    </>
  )
}

export default MortgagesInfo