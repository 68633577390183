import React from "react";

import {BsArrowDown} from 'react-icons/bs'

import MortgagesInfo from "./MortgagesInfo";

const Mortgages = () => {
 

  return (
    <>
      <div className="  ">
        <div className="w-full  text-center grid justify-items-center  mb-0  ">
          <div className="text-5xl font-head2 md:text-9xl ">MORTGAGES</div>
          <marquee direction="left" width="100%" height="30">"Not all buy to let mortgages are regulated by the financial conduct authority."  "As with all insurance policies, conditions and exclusions will apply." </marquee>
          <div className=" font-letter text-center w-full p-4 text-xl md:text-center md:px-40">
            Whether you are a first time buyer, considering purchasing your next
            property, or remortgaging, my advice can help. Please click below
            for more information on the services I provide, or contact me to
            discuss further.
          </div>
        </div>
        <div className="grid justify-items-center items-center">
          <div className="grid   w-11/12 md:grid-cols-2 justify-items-center items-center grid-rows-2 md:grid-rows-none  gap-16 md:gap-48">
            <div id="RESIDENTIAL" className="border  grid justify-items-center w-full shadow-xl hover:shadow-2xl hover:cursor-pointer group-hover:text-blue-600" >
              <img id="res" src="../home1.jpg" className="w-full " alt='' ></img>
              <div id="Res" className=" font-bold text-orange-400 cursor-pointer hover:text-blue-600 text-center m-4">
                RESIDENTIAL
              </div>
            </div>
            {/* <div id="HELP" className="border  grid justify-items-center w-full shadow-xl hover:shadow-2xl hover:cursor-pointer" >
              <img id="help" src="../home2.jpg" className="w-full " alt=''></img>
              <div id="Help" className=" font-bold text-orange-400 cursor-pointer hover:text-blue-600 text-center m-4">
                HELP TO BUY
              </div>
            </div> */}
            <div id="BUY" className="border  grid justify-items-center w-full shadow-xl hover:shadow-2xl hover:cursor-pointer">
              <img id="buy" src="../home3.jpg"  className="w-full " alt=''></img>
              <div  id="buY" className=" font-bold text-orange-400 cursor-pointer hover:text-blue-600 text-center m-4">
                BUY TO LET
              </div>
            </div>
          </div>
        </div>
        <div className="w-full grid items-center mt-10 border-b-2  gap-0  md:gap-4 md:border-0 justify-items-center">
         <div className="font-head  text-xl md:text-3xl ">
           KNOW MORE ABOUT MORTGAGES
          </div>
          <div className="scale-0 md:scale-100">
            {/* <BiArrowToBottom /> */}
            <BsArrowDown size={60}/>
            
          </div>
        </div>
      </div>
      <MortgagesInfo/>
    </>
  );
};

export default Mortgages;
