import React from 'react'
import styles from './header.module.css'
import logo  from '../../assets/LOGO1.jpg'

const Header = () => {
  return (
    <div className={styles.container}>
    <img src={logo} alt='/'></img>


     <div className={styles.first}>

        
        <h1>PARUL CHHABRA</h1>

        
        
        <p>Mortgage & Protection Advice at 
            <br></br>APSM Financial Services</p>
            
     </div>
    </div>

  )
}

export default Header