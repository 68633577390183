import React from 'react'
import {BsArrowDown} from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'

const MoreInfo = () => {
  const Navigate = useNavigate()
  return (
    <>
    <div className='h-full w-full grid     md:grid-rows-5 justify-items-center p-4'>
      <div className=''>
       <div className='text-3xl md:text-7xl font-head2 text-center'>WORKING WITH YOU</div>
       <marquee direction="left" width="100%" height="30">"Not all buy to let mortgages are regulated by the financial conduct authority."  "As with all insurance policies, conditions and exclusions will apply." </marquee>
       <div className='grid justify-items-center '>

       <div className='text-lg font-letter text-center md:w-11/12 '>Getting to know you: I will take the time to learn more about you, your circumstances, and your overall financial position. I would also like to hear your thoughts on the type of mortgage you believe is right for you before discussing the pros and cons of each option with you.</div>
       </div>
         
      </div>
      <div>
          <div className='m-5  text-xl text-orange-600 font-letter text-center' >RESEARCHING THE OPTIONS</div>
          <div className='font-letter text-lg'>Using our expert knowledge and an extensive database of several thousand mortgages, I will identify the options that best suit your needs.</div>
          </div>
         <div>
            <div className='m-5  text-xl text-orange-600 font-letter text-center' >RECOMMENDING THE RIGHT SOLUTION</div>
           <div className='font-letter text-lg text-center'>Once I have identified the available options, I will meet with you again or discuss our recommendations over the phone. Additionally, I will provide written details for you to review, explaining what I have suggested and why. If you are happy with our recommendation, I will assist you in completing the application forms and liaise on your behalf with solicitors, valuers, and surveyors. I will also guide you through the essential aspects of financially protecting your new property. Throughout the process and beyond, I will stay in touch to ensure everything progresses smoothly.</div>
         </div>
         <div className='w-full h-fit grid justify-items-center'>
         <button className=" bg-transparent hover:bg-orange-300 text-orange-300 font-semibold hover:text-white py-2 px-4 border border-orange-300 hover:border-transparent rounded mb-4 w-1/3"
         
         onClick={()=>{Navigate('/Contact')}} > Get In Touch </button> 
         </div>
         <div className=' md:scale-100 grid grid-rows-2 gap-4' >
          <div className='font-head  text-xl md:text-3xl'>More Info</div>
          <div className=" md:scale-100 grid justify-items-center">
            {/* <BiArrowToBottom /> */}
            <BsArrowDown size={60}/>
            
          </div>
           </div>
    </div>
     <div className='h-fit w-full grid gap-5 justify-items-center'>
      <div className=' md:text-5xl text-xl  text-center '>WHAT ELSE DO YOU NEED TO KNOW?</div>
       <div className='grid gap-5 grid-rows-2 md:w-11/12 md:grid-rows-none md:grid-cols-2'>
       <div className='grid gap-5 text-center md:text-start'>
          <div className='font-head2 text-5xl text-orange-500'>SURVEYING</div>
          <div className='font-letter text-lg '>your lender will instruct a survey to confirm that the price you’re paying for the property is appropriate. The most common types of survey are:</div>
          <div className='font-letter text-lg'>
            <span className='font-head mr-2'>
              Basic mortgage valuation
              </span>
              This is for the lender’s own purposes to confirm that the property provides security for the loan.
            </div>
            <div className='font-letter text-lg'>
            <span className='font-head mr-2'>
             Homebuyer’s report
              </span>
              This provides brief information on the property’s condition. The report will include comments on the property’s defects and the valuer’s opinion on its marketability.
            </div>
            <div className='font-letter text-lg'>
            <span className='font-head mr-2'>
            Full structural survey 
              </span>
              This report is the most comprehensive survey. It is based on a detailed examination of the property.
            </div>
            
        </div>
        <div>
          <div className='font-head2 text-5xl text-orange-500 text-center md:text-start'>SOLICITORS</div>
          <div className='font-letter text-lg '>Before going ahead with a property purchase you may need to appoint a solicitor or conveyancer to act on your behalf. They will undertake the legal work required to ensure the ownership (title) of the property and land transfers successfully. If you don’t already have a solicitor who undertakes conveyancing work, I can recommend one using a specialist company that provides access to a nationwide network of solicitors. Some lenders will offer to pay for the basic mortgage valuation as an incentive. You may also want to consider one of the more detailed surveys, depending on the age and condition of the property. In most cases you can use the same surveyor to carry out both surveys, but there’s nothing to stop you appointing an independent surveyor should you choose to do so. I can help you do this.</div>
        </div>

       </div>
       <div className='text-xl font-gead2'>Solicitors, valuers and surveyors are not regulated by the Financial Conduct Authority.</div>
       <div className='text-white bg-black w-full text-center p-8'>The information on this wbsite if for the use of residents of the United Kingdom only. No representations are made as to whether the information is available or applicable in any other country which may have access to it.</div>
     </div>

    
    </>
  )
}

export default MoreInfo