
import { Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './pages/home';
import Contact from './Components/Contact';

import MoreInfo from './Components/MoreInfo';
import Mortgages from './Components/Mortgages';

import Protection from './Components/Protection';
import HomeInsurance from './Components/homeinsurance';
import Privacypolicy from './pages/privacypolicy';
import Justintime from './pages/justintime';


function App() {
  return (
    <div className="App">
     
    <Routes>
      <Route path='/' element={<Home/>}/>
      {/* // <Route   path='/'  element={<Landingpage/>} /> */}
      <Route   path='/Contact'  element={<Contact/>} />
      <Route  path='/Mortgages' element={<Mortgages/>} />
      <Route  path='/Protection' element={<Protection/>} />
      <Route  path='/MoreInfo' element={<MoreInfo/>} />
      <Route  path='/homeinsurance' element={<HomeInsurance/>} />
      <Route path='/privacypolicy' element={<Privacypolicy/>}/>
      <Route path='/justintime' element={<Justintime/>}/>
      
    </Routes>

    </div>
  );
}

export default App;
